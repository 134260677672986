*, *::before, *::after {
  box-sizing: border-box;    
} 
html {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fff;
  font: 16px/1.5 sans-serif;
  -webkit-tap-highlight-color: rgba(#fff, 0);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-top: 0px !important;
}
html.disabled body, html.disabled {
  overflow-y: hidden;
  height: 100%;
}
body {
  height: 100vh;
  margin: 0;
  background:#fff;
  color: #000;
  font-family: 'Poppins', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
body.compensate-for-scrollbar {
  margin: 0;
}

img,iframe,svg {
  display: block;
}
svg {
  fill: currentColor;
}
a {
  display: block;
  text-decoration: none;
  color: inherit;
}
p {
  margin-top: 0;
}
figure {
  margin: 0;
}
input {
  width: 100%;
  background: transparent;
  border: none;
  font-family: inherit;
  color: inherit;
  outline: none;
}
input:required {
  box-shadow: none;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
  appearance: none;
}
button {
  border: none;
  background: transparent;
  font: inherit;
  appearance: none;
  cursor: pointer;
}
textarea{
  resize: none;
  font-family: sans-serif, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  outline: none;
}
:focus {
  outline: none;
}
:active {
-webkit-tap-highlight-color: rgba(0,0,0,0);
}
::placeholder {
color: inherit;
opacity: 1;
}
.grecaptcha-badge { 
  visibility: hidden;
}

/* Load */

.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
  color: #fff;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 8px;
  border: 2px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring-l div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 8px;
  border: 2px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring-xl div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 35px;
  height: 35px;
  margin: 8px;
  border: 2px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



/* SETANDO PADROES DO CONTAINER*/
.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}


/* SETANDO PADROES DAS IMAGENS*/
.img-responsive {
  max-width: 100%;
  display: block;
  height: auto;
}
.img-cover, .img-contain {
  display: block;
  width: 100%;
  height: 100%;
}
.img-cover {
  object-fit: cover;
}
.img-contain {
  object-fit: contain;
}
@media only screen and (min-width: 768px){
  .container {
      max-width: 750px;
  }
}
@media only screen and (min-width: 991px){
  .container {
      max-width: 970px;
  }
}
@media only screen and (min-width: 1200px){
  .container {
      max-width: 1170px;
  }
}
@media only screen and (min-width: 1600px){
  .container {
      max-width: 1330px;
  }
}
@font-face {
  font-family: 'Poppins Thin';
  src: url('./assets/fonts/Poppins-Thin.ttf');
}
@font-face {
  font-family: 'Poppins Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Poppins Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf');
}
@font-face {
  font-family: 'Poppins Semi Bold';
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: 'Poppins Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'AlumniSans Medium';
  src: url('./assets/fonts/AlumniSans-Medium.ttf');
}

@font-face {
  font-family: 'Inter Thin';
  src: url('./assets/fonts/Inter-Thin.ttf');
}
@font-face {
  font-family: 'Inter Regular';
  src: url('./assets/fonts/Inter-Regular.ttf');
}
@font-face {
  font-family: 'Inter Medium';
  src: url('./assets/fonts/Inter-Medium.ttf');
}
@font-face {
  font-family: 'Inter Semi Bold';
  src: url('./assets/fonts/Inter-SemiBold.ttf');
}
@font-face {
  font-family: 'Inter Bold';
  src: url('./assets/fonts/Inter-Bold.ttf');
}
